import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  media: {
    height: 140,
  },
}));

export default function StudentCards({ studentList, onStudentSelect }) {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justifyContent="center">
      {studentList.map((student) => (
        <Grid item key={student._id}>
          <Card
            className={classes.root}
            style={{ width: "200px", cursor:"pointer" }}
            onClick={() => onStudentSelect(student._id,student.classId._id)}
          >
            <CardMedia
              style={{ width: "100px" }}
              className={classes.media}
              image={student.imageURL[0] || "/images/Admin icon.svg"}
              title={student.fullName}
            />
            <CardContent style={{ padding: "none" }}>
              <Typography variant="h6" component="h2">
                {student.fullName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Class: {student.classId.className}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
