import React, { useState, useEffect } from "react";
import {
    Button, Card, Box, Select, MenuItem, FormControl, InputLabel,
    Grid, TextField, Typography, Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttendenceService from "./Locality/Service/attendenceService";
import StudentService from "./Locality/Service/studentService";
import AddClassService from "./Locality/Service/addClassService";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f6f8",
        padding: theme.spacing(3)
    },
    card: {
        width: "90%",
        maxWidth: "900px",
        padding: theme.spacing(3),
        boxShadow: theme.shadows[3],
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    studentRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 0),
        borderBottom: "1px solid #ddd"
    },
    submitButton: {
        marginTop: theme.spacing(2),
        backgroundColor: "#0f3b62",
        color: "white",
    }
}));

export default function Attendance() {
    const classes = useStyles();
    const [dateValue, setDateValue] = useState(new Date().toISOString().split("T")[0]);
    const [classValue, setClassValue] = useState("");
    const [studentList, setStudentList] = useState([]);
    const [addClassList, setAddClassList] = useState([]);

    useEffect(() => {
        getAddClassList();
    }, []);

    const getAddClassList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
            setAddClassList(res);
        }).catch((err) => {
            console.error(err);
        });
    };

    const getStudentList = (event) => {
        const classId = event.target.value;
        setClassValue(classId);
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        StudentService.getAllStudentById(userDetails.schooleId, { classId }).then((res) => {
            setStudentList(res.map(student => ({ ...student, status: true })));
        }).catch((err) => {
            console.error(err);
        });
    };

    const handleCheck = (studentId) => {
        setStudentList(prev => prev.map(student => 
            student._id === studentId ? { ...student, status: !student.status } : student
        ));
    };

    const handleSubmit = () => {
        if (!classValue) {
            alert("Please select a class.");
            return;
        }
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const attendanceData = {
            classId: classValue,
            schooleId: userDetails.schooleId,
            studentList,
            teacherId: userDetails._id,
            date: dateValue
        };
        AttendenceService.creteAttendence(attendanceData).then(() => {
            alert("Attendance Submitted Successfully");
        }).catch(err => {
            alert(err.response.data.message);
        });
    };

    return (
        <div >
            <Card className={classes.card}>
                <Typography variant="h4" className={classes.title}>Attendance</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Date"
                            type="date"
                            value={dateValue}
                            onChange={(e) => setDateValue(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Select Class</InputLabel>
                            <Select value={classValue} onChange={getStudentList}>
                                <MenuItem value=""><em>None</em></MenuItem>
                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography variant="h6" style={{ marginTop: 20 }}>Student Details:</Typography>
                {studentList.map((student) => (
                    <Box key={student._id} className={classes.studentRow}>
                        <Typography>{student.firstName} {student.lastName}</Typography>
                        <Checkbox checked={student.status} onChange={() => handleCheck(student._id)} />
                    </Box>
                ))}
                <div style={{ textAlign: 'center' }}>
                <Button className={classes.submitButton}  onClick={handleSubmit}>
                    Submit
                </Button>
                </div>
            </Card>
        </div>
    );
}
