import React, { useState } from "react";
import {
    LinearProgress,
    OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell, Checkbox
} from "@material-ui/core";
// import Checkbox from '@material-ui/Checkbox';
import schooleRegistration from "./Locality/Service/schooleRegistration"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';

import { useContext, useEffect } from 'react';
import { useTheme } from "@material-ui/styles";

import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    YAxis,
    XAxis,
} from "recharts";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';
import SchooleRegistrationService from "./Locality/Service/schooleRegistration";
export default function Term(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [schooleRegistrationList, setSchooleRegistrationList] = useState([]);
    const [schooleRegistration, setSchooleRegistration] = useState({
        schooleName: '',
        address: '',
        city: '',
        pincode: '',
        selectCountry: '',
        name: '',
        email: '',
        mobileNumber: '',
        schooleEmail: '',
        schooleContact: '',
        password: '',
        subscription: '',
        terms: '',
    });

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const validationSchema = Yup.object().shape({
        schooleName: Yup.string().required('schoole Name  is required'),
        address: Yup.string().required('address is required'),
        city: Yup.string().required('city is required'),
        pincode: Yup.string().required('pin code is required')
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits'),
        selectCountry: Yup.string().required('selectCountry is required'),
        name: Yup.string().required('name is required'),
        email: Yup.string(),
        mobileNumber: Yup.string().required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        schooleEmail: Yup.string()
            .required('schoole Email is required'),
        schooleContact: Yup.string().required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        password: Yup.string().required('password is required'),
        subscription: Yup.string(),
        terms: Yup.string().required('Terms and Conditions is required'),
    });
    const getSchooleRegistrationList = () => {
        SchooleRegistrationService.getAllSchooleRegistration().then((res) => {
            setSchooleRegistrationList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    // onSubmitHandler = (e) => {
    //   e.preventDefault();
    //  this.props.history.push('/login')
    // }
    const formik = useFormik({
        initialValues: schooleRegistration,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            SchooleRegistrationService.creteSchooleRegistration(values).then((res) => {

                alert(" Registration Successfully.");
                props.history.push('/montessori/login');
            })
                .catch((err) => {
                    alert(err.response.data.message);
                })
        },
    });


    return (

        <>
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div style={{ marginLeft: "7%" }}>



                        <Grid container spacing={2} columns={12} >
                           
                                <PageTitle InputProps={{ style: { color: '#10b680' } }} title=" Terms & Conditions" ></PageTitle>
                            </Grid>
                            <b>Below are draft terms and conditions for www.montestory.in School Activity Monitor Software. The content includes key clauses often found in software agreements, tailored specifically for educational environments and software use.
                                Last updated on 25th Nov,2024
                            </b>
                            <br></br>
                            <br></br>
                            <h3>Terms and Conditions for Montessori School Activity Monitor Software</h3>
                            
                            <br></br>
                        <h3>1. Introduction</h3>
                        <p>Welcome to www.montestory.in  an activity monitoring tool designed specifically for Montessori schools to enhance the educational experience. By using the Software, you agree to comply with and be bound by the following Terms and Conditions. If you do not agree with these Terms, please do not use the Software.</p>
                        <h3>2. Definitions</h3>
                        <p>•	<b>Software:</b> Refers to the Montessori School Activity Monitor Software provided by Srshta Tech Solutions Pvt Ltd.</p>
                        <p>•	<b>User:</b> Any individual or institution authorized to use the Software.</p>
                        <p>•	<b>School:</b> The Montessori school or educational institution using the Software.</p>
                        <p>•	<b>Administrator:</b> The person(s) designated by the School with permissions to manage and oversee Software use.</p>
                        <p>•	<b>Data:</b> Any data, information, or records collected or generated through the use of the Software.</p>
                        <h3>3. License</h3>
<p>The Software is licensed, not sold. Srshta Tech Solutions Pvt Ltd. grants the School a non-exclusive, non-transferable, limited license to use the Software for educational purposes. This license is subject to the following conditions:</p>
<p>•	The Software may only be used by authorized staff members of the school.</p>
<p>•	The Software may not be resold, distributed, or sublicensed to third parties.</p>
<p>•	Unauthorized use of the Software is strictly prohibited.</p>
<h3>4. User Obligations</h3>
<p>By using the Software, the School and its Users agree to:</p>
<p>•	Use the Software solely for educational purposes in accordance with Montessori principles.</p>
<p>•	Not to use the Software in any manner that may compromise its security or functionality.</p>
<p>•	Not to modify, reverse engineer, or attempt to derive the source code of the Software.</p>
<p>•	Ensure all Users comply with these Terms and Conditions.</p>
<h3>5. Data Privacy and Security</h3>
<p>•	The Software may collect and store data related to student activities. All data is processed and managed in accordance with applicable data protection laws.</p>
<p>•	The school is responsible for ensuring that it has obtained the necessary permissions from parents or guardians before collecting any student data.</p>
<p>•	<b>Srshta Tech Solutions Pvt Ltd. </b>is committed to ensuring the security of any data collected by the Software and will take reasonable precautions to protect it from unauthorized access or misuse.</p>
<h3>6. Restrictions</h3>
<p>•	Users may not use the Software to engage in any illegal or unauthorized activities.</p>
<p>•	Users shall not attempt to gain unauthorized access to the Software or its underlying systems.</p>
<p>•	The Software may not be used in a manner that interferes with the educational environment or disrupts learning.</p>
<h3>7. Software Updates and Maintenance</h3>
<p>•	<b>Srshta Tech Solutions Pvt Ltd</b> reserves the right to update, modify, or discontinue the Software at any time, with or without notice.</p>
<p>•	Updates may be required to maintain the Software’s functionality and security.</p>
<p>•	The school is responsible for ensuring that its systems meet the minimum requirements to run the Software.</p>
<h3>8. Support and Maintenance</h3>
<p>•	<b>Srshta Tech Solutions Pvt Ltd </b>will provide technical support as outlined in a separate Service Level Agreement (SLA), if applicable.</p>
<p>•	Support requests should be directed to <b>Support@ Email/Contact Information.</b></p>
<p>•	The school agrees to cooperate with <b>Srshta Tech Solutions Pvt Ltd </b> during troubleshooting and support activities.</p>
<h3>9. Fees and Payment</h3>
<p>•	The Software may be subject to subscription fees or one-time charges, as outlined in a separate pricing agreement.</p>
<p>•	Payment terms and conditions, including billing cycles, are detailed in the applicable pricing agreement.</p>
<p>•	Failure to pay fees may result in suspension or termination of access to the Software.</p>
<h3>10. Limitation of Liability</h3>
<p>•	<b>Srshta Tech Solutions Pvt Ltd </b> shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use the Software.</p>
<p>•	In no event shall <b>Srshta Tech Solutions Pvt Ltd</b>’s total liability exceed the amount paid by the School for the Software over the previous 12 months.</p>
<h3>11. Intellectual Property</h3>
<p>•	All intellectual property rights related to the Software, including but not limited to trademarks, logos, source code, and documentation, are owned by <b>Srshta Tech Solutions Pvt Ltd.</b></p>
<p>•	The school is granted no rights other than those expressly stated in this agreement.</p>
<h3>12. Termination</h3>
<p>•	This agreement may be terminated by either party if there is a material breach of these Terms.</p>
<p>•	<b>Srshta Tech Solutions Pvt Ltd. </b>reserves the right to terminate access to the Software at any time if the school fails to comply with these Terms.</p>
<h3>13. Governing Law and Dispute Resolution</h3>
<p>•	These Terms and Conditions are governed by the laws in Jurisdiction of Hyderabad, Telangana, India.</p>
<p>•	Any disputes arising from the use of the Software will be subject to mediation or arbitration before pursuing litigation.</p>
<h3>14. Changes to Terms and Conditions</h3>
<p>•	<b>Srshta Tech Solutions Pvt Ltd </b> reserves the right to modify these Terms and Conditions at any time. Any changes will be communicated to the school via email or through an update in the Software.</p>
<p>•	Continued use of the Software after any modifications constitutes acceptance of the revised Terms.</p>
<h3>15. Contact Information</h3>
<p>For any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
<p><b>•	Email: support@montestory.in</b></p>
                    </div>
                </Box>

            </Card>


        </>
    );
}


