import React, { useState } from "react";
import { Button, FormControl, InputLabel, MenuItem, Radio,
    RadioGroup,
    FormControlLabel,
    
    FormLabel } from "@material-ui/core";

import StudentService from "./Locality/Service/studentService";
import AddClassService from "./Locality/Service/addClassService";
import * as Yup from 'yup';
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function StudentForm(props, history) {
    const { id } = useParams();
    var [error, setError] = useState(null);
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [cityList, setCityList] = useState([]);
    const [imageslist, setImagesList] = useState('');
    const [message, setMessage] = useState("");
    const [images, setImages] = useState('');
    const classes = useStyles();
    const [gender, setGender] = useState("");
    const [studentList, setStudentList] = useState([]);
      const [isEditMode, setIsEditMode] = useState(false);
      const [editId, setEditId] = useState(null);
    const [student, setStudent] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        motherName: '',
        fatherName: '',
        mobileNumber: '',
        address: '',
        lane: '',
        // selectCity: '',
        doa: '',
        allergies: '',
        classId: '',
        fatherMobileNumber: '',
        admissionNumber: '',
        gender: '',
        adharNo:'',
    });

    const city = [
        'Mumbai',
        'Delhi',
        'Bagolore',
        'Hyderbad',
        'Chennai',
    ];
    const [addClassList, setAddClassList] = useState([]);
    useEffect(() => {
        getAddClassList();
        if (id !== 'add') {
            getByIdList();
        }
        return () => {
            setStudentList([]);
        }
    }, []);
    const handleChange = (student) => {
        setAge(student.target.value);
    };
    const handleChange1 = (event) => {
        setGender(event.target.value);
      };
    const handleOpen = () => {
        props.history.push('/app/district/studentdetails')
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getByIdList = () => {
        StudentService.getByIdStudent(id).then((res) => {

            setStudent(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
   
    const handleFileChange = (e) => {
        setImages(e.target.files); // Store all selected files in state
    };
    const validationSchema = Yup.object().shape({
        admissionNumber: Yup.string().required(' required'),
        firstName: Yup.string().required('student first name  is required'),
        lastName: Yup.string(),
        gender: Yup.string(),
        adharNo:Yup.string().
        matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Must be exactly 12 digits')
        .max(10, 'Must be exactly 12 digits'),
        dob: Yup.string().required('select date of birth'),
        classId: Yup.string().required('select class'),
        motherName: Yup.string().required('mother name  is required'),
        fatherName: Yup.string(),
        mobileNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        fatherMobileNumber: Yup.string(),
        address: Yup.string(),
        lane: Yup.string(),
        // selectCity: Yup.string().required('select city'),
        doa: Yup.string().required('select date of admission'),
        allergies: Yup.string(),
        
    });
    
  
    const getStudentList = () => {
        StudentService.getAllStudent().then((res) => {
            setStudentList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getAddClassList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {

            setAddClassList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const handleUpload = async (e) => {
        e.preventDefault();

        if (images.length === 0) {
            setMessage("Please select images first.");
            return;
        }

        // Create FormData and append each file
        const formData = new FormData();
        if (images.length > 0) {
            const image = images[0];
            formData.append(`image`, image);

            formData.append(`imageName`, image.name);
            // images.forEach((image, index) => {
            //   formData.append(`images`, image); // Append each file to the form
            // });

            try {
                // Send the images to the API
                StudentService.uploadImage(formData).then((res) => {

                    alert(`Images uploaded successfully`);
                    setImagesList((prev) => [...prev, res.url]);


                }).catch((err) => {
                    alert(err.message);
                });
            } catch (error) {
                console.error("Error uploading images:", error);
                setMessage("An error occurred while uploading the images.");
            }
        }

    };
    const deleteStudent = (studentdelete) => {
        if (studentdelete) {
            StudentService.deleteStudent(studentdelete).then((res) => {
                getStudentList();
            }).catch((err) => {
            });
        }
    };
    const getStudentImageList = (studentId) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));

        const keys = {
            "schooleId": userDetails.schooleId,

            "studentId": userDetails.studentId
        }
        StudentService.creteImages(keys).then((res) => {

            //   setStudentList([]);
            alert(" Event Successfully.");
        }).catch((err) => {

        });
    }
    const formik = useFormik({
        initialValues: student,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.schooleId = userDetails.schooleId
            if (student._id) {
                StudentService.upadeStudent(values).then((res) => {
                    setImagesList([])
                    alert("Student Registration Updated Successfully.");
                    props.history.push('/app/studentdetails');

                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }
            else {
                values.imageURL = imageslist;
                StudentService.creteStudent(values).then((res) => {
                    setImagesList([])
                    alert(" Student Registration Successfully.");
                    props.history.push('/app/studentdetails');
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }
        },
    });

    return (
        <>
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div style={{ marginLeft: "7%" }}>
                        <Grid container spacing={2} columns={12} >
                            <Grid item xs={12}>
                                <PageTitle InputProps={{ style: { color: '#10b680' } }} title=" Student Registration" ></PageTitle>
                            </Grid>
                          
                            <Grid item xs={6}>
                                <TextField InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                   
                                    id="admissionNumber"
                                    name="admissionNumber"
                                    label="Admission Number "
                                    type="text"
                                    autoFocus
                                    variant="standard"
                                    value={formik.values.admissionNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.admissionNumber && Boolean(formik.errors.admissionNumber)}
                                    helperText={formik.touched.admissionNumber && formik.errors.admissionNumber}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {/* <form className={classes.container} noValidate> */}
                                <TextField InputProps={{ style: { width: 370 } }}
                                    id="dob"
                                    name="dob"
                                    label="Date Of Birth"
                                    type="date"
                                    min="2016-11-10"
                                    max="2022-11-10"
                                    // defaultValue="2017-05-24"
                                    value={formik.values.dob}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                                    helperText={formik.touched.dob && formik.errors.dob}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {/* </form> */}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    
                                    id="firstName"
                                    name="firstName"
                                    label="First Name "
                                    type="text"
                                    variant="standard"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField InputProps={{ style: { width: 370 } }}
                                    margin="dense"

                                    id="lastName"
                                    name="lastName"
                                    label="Last Name "
                                    type="text"
                                    variant="standard"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <FormControl component="fieldset">
  <FormLabel component="legend">Gender</FormLabel>
  <RadioGroup
    row
    name="gender"
    id="gender"
    value={formik.values.gender}
    onChange={formik.handleChange}
  >
    <FormControlLabel value="male" control={<Radio />} label="Male" />
    <FormControlLabel value="female" control={<Radio />} label="Female" />
  </RadioGroup>
</FormControl>
                            </Grid>
                           
                            <Grid item xs={6}>
                                <div style={{ width: 370 }}>
                                    <FormControl className={classes.formControl} fullWidth="true"
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="classId"
                                            label="selectClass"
                                            value={formik.values.classId}
                                            onChange={formik.handleChange}
                                            error={formik.touched.classId && Boolean(formik.errors.classId)}
                                            helperText={formik.touched.classId && formik.errors.classId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {addClassList.map(({ _id, className }) => (
                                                <MenuItem key={_id} value={_id}>{className}

                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    id="motherName"
                                    name="motherName"
                                    label="Mother Name"
                                    type="text"
                                    variant="standard"
                                    value={formik.values.motherName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.motherName && Boolean(formik.errors.motherName)}
                                    helperText={formik.touched.motherName && formik.errors.motherName}
                                />
                            </Grid>
                            <Grid item xs={6}>

                                <TextField
                                    
                                    margin="dense"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    label="Mother Number(parent login)"
                                    type="text"
                                    variant="standard"
                                    value={formik.values.mobileNumber}
                                    // value={editId ? formik.values.mobileNumber:''}
                                    InputProps={{ style: { width: 370 }}}
                                    onChange={formik.handleChange}
                                    error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                    helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    id="fatherName"
                                    name="fatherName"
                                    label="Father Name"
                                    type="text"
                                    variant="standard"
                                    value={formik.values.fatherName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fatherName && Boolean(formik.errors.fatherName)}
                                    helperText={formik.touched.fatherName && formik.errors.fatherName}
                                />
                            </Grid>
                            <Grid item xs={6}>

                                <TextField
                                    InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    id="fatherMobileNumber"
                                    name="fatherMobileNumber"
                                    label="Father Number"
                                    type="text"
                                    variant="standard"
                                    value={formik.values.fatherMobileNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fatherMobileNumber && Boolean(formik.errors.fatherMobileNumber)}
                                    helperText={formik.touched.fatherMobileNumber && formik.errors.fatherMobileNumber}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '30px' }}>
                                <span style={{ fontSize: '17px', color: '#0f3b62ad', }} >Uplaod Student Adhar Card:</span>
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    // Enable multiple file selection
                                    onChange={handleFileChange}
                                />
                                <Button style={{ backgroundColor: 'rgb(15 59 98)', color: 'white', border: 'none' }} onClick={handleUpload}>Upload</Button>

                                {imageslist.length > 0 && (
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                        {imageslist.map((url, index) => (
                                            <img
                                                key={index}
                                                src={url}
                                                alt={`Uploaded ${index + 1}`}
                                                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Grid>
                            
                            <Grid item xs={6}>

                                    <TextField
                                        InputProps={{ style: { width: 370 } }}

                                        margin="dense"
                                        id="adharNo"
                                        name="adharNo"
                                        label="Aadhaar number"
                                        type="text"
                                        variant="standard"
                                        value={formik.values.adharNo}
                                        onChange={formik.handleChange}
                                        error={formik.touched.adharNo && Boolean(formik.errors.adharNo)}
                                        helperText={formik.touched.adharNo && formik.errors.adharNo}
                                    />
                                </Grid>
                               
                            <Grid item xs={12} style={{ marginTop: '30px' }}>
                                <span style={{ fontSize: '17px', color: '#0f3b62ad' }} >Address:</span>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    id="address"
                                    name="address"
                                    placeholder="Door/Flat/domicile number "
                                    label="Address "
                                    type="text"
                                    variant="standard"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                    helperText={formik.touched.address && formik.errors.address}
                                />



                            </Grid>

                            <Grid item xs={6}>
                                {/* <form className={classes.container} noValidate> */}
                                <TextField InputProps={{ style: { width: 370 } }}
                                    id="doa"
                                    name="doa"
                                    label="Date Of Admission "
                                    type="date"
                                    defaultValue="2017-05-24"
                                    value={formik.values.doa}
                                    onChange={formik.handleChange}
                                    error={formik.touched.doa && Boolean(formik.errors.doa)}
                                    helperText={formik.touched.doa && formik.errors.doa}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {/* </form> */}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    InputProps={{ style: { width: 370 } }}
                                    margin="dense"
                                    id="allergies"
                                    name="allergies"
                                    label="Allergies if any"
                                    type="text"
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    value={formik.values.allergies}
                                    error={formik.touched.allergies && Boolean(formik.errors.allergies)}
                                    helperText={formik.touched.allergies && formik.errors.allergies}
                                />
                            </Grid>
                        </Grid>

                        <div style={{ textAlign: 'right', margin: '29px' }}>
                            <Button style={{ backgroundColor: ' rgb(15 59 98)', color: 'white' }} onClick={formik.handleSubmit}
                                type="submit" variant="contained">Submit</Button>
                        </div>

                    </div>
                </Box>
            </Card>
        </>
    );
}


