import requests from './httpService';

const StudentService = {
  getAllStudent(id) {
    return requests.get(`/student/list/${id}`);
  },
//   upadeCity(body) {
//     return requests.put(`/city/${body.id}`,body); 
//   },
findStudentList(body){
  return requests.post(`/student/find`,body); 
},
  creteStudent(body){
    return requests.post('/student/add',body); 
  },
  getAllStudentById(id,body){
    return requests.post(`/student/list/${id}`,body); 
  },
  deleteStudent(id){
    return requests.delete(`/student/${id}`); 
  },
  upadeStudent(body) {
    return requests.put(`/student/${body._id}`,body); 
  },
  getByIdStudent(id) {
    return requests.get(`/student/${id}` );
  },
  upadeStudent(body) {
    return requests.put(`/student/${body._id}`,body); 
  },
  uploadImage(body) {
    return requests.post(`/student/uploadimage`,body);
  },
  creteImages(body){
    return requests.post('/image/add',body);
  },
};

export default StudentService;
