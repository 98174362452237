import React, { useEffect, useState } from "react";
import { TablePagination, Button, TableRow, Table, TableHead, TableBody, Typography, TableCell, Card, Box, Grid } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import EventService from "./Locality/Service/eventService";
import StudentService from "./Locality/Service/studentService";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import UserProfileCard from "./UserProfileCard";
import StudentCards from "./UserProfileCard";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0f3b62ad",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function Assignment() {
  const classes = useStyles();
  const [eventList, setEventList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    fetchStudentList();
  }, []);



  const handleStudentClick = (studentId, classId) => {

    getEventList(studentId, classId);

    // You can now fetch the student's assignments or do anything with this ID
  };

  const fetchStudentList = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    StudentService.findStudentList({ userId: userDetails._id })
      .then((res) => {
        const mappedStudents = res.map((student) => ({
          ...student,
          fullName: `${student.firstName} ${student.lastName}`,
        }));
        setStudentList(mappedStudents);
        if(mappedStudents.length>0){
            getEventList(mappedStudents[0]._id, mappedStudents[0].classId._id);
        }
        
      })
      .catch((err) => console.error(err));
  };

  const getEventList = (studentId, classId) => {
    EventService.findEventList({ studentId, classId })
      .then((res) => {
        const formattedEvents = res.map((event) => ({
          ...event,
          date: event.date ? formatDate(event.date) : "",
        }));
        setEventList(formattedEvents);
      })
      .catch((err) => console.error(err));
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year.slice(2)}`;
  };

  return (
    <>
      <PageTitle title="Assignment" />
      
        <>
          <Card sx={{ maxWidth: 345 }}>
           {studentList.length>1 &&( <Box style={{ margin: 15 }}>
            <StudentCards studentList={studentList} onStudentSelect={handleStudentClick} />
     
            </Box>)}
          </Card>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget title="Assignments" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Assignment</StyledTableCell>
                      <StyledTableCell>Assignment Date</StyledTableCell>
                      <StyledTableCell>Due Date</StyledTableCell>
                      <StyledTableCell>Remarks</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Request for Resubmission</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventList.map((event) => {
                      const isPastDue = new Date() > new Date(event.dueDate);
                      return (
                        <TableRow key={event._id}>
                          <TableCell>{event.eventName}</TableCell>
                          <TableCell>{formatDate(event.createdAt)}</TableCell>
                          <TableCell>{event.dueDate}</TableCell>
                          <TableCell>{event.description}</TableCell>
                          <TableCell>{event.status}</TableCell>
                          <TableCell>
                            {isPastDue && event.resubmit === "Not Submitted" && (
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "#0f3b62ad", color: "white" }}
                                onClick={() => console.log("Resubmit", event)}
                              >
                                Resubmit
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 25, 50]}
                  count={eventList.length}
                  page={0}
                  rowsPerPage={10}
                  onPageChange={() => {}}
                />
              </Widget>
            </Grid>
          </Grid>
        </>
     
    </>
  );
}
