import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Box,
  makeStyles,
  Paper,
  Container,
  Link,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, InputAdornment,IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";
import { ChildCareSharp } from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import "./login.css";
import CategoryServices from "../../services/CategoryServices";
import { loginUser } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import { useUserDispatch } from "../../context/UserContext";
// -------------------- STYLES --------------------
const useResponsiveStyles = makeStyles((theme) => ({
  container: {
    // The parent Grid's container style
    display: "flex",
  },
  mainBox: {
    // Styles for the white card area
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "3%",
    padding: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "1200px",
    // Media query for small screens
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "5%",
      padding: "15px",
    },
  },
  icon: {
    marginBottom: theme.spacing(1),
  },
  iconImage: {
    width: 25,
    height: "auto",
  },
  studentButton: {
    backgroundColor: "#E0ECFF", // Pastel blue
    color: "#333",
    borderRadius: theme.shape.borderRadius * 3,
    textTransform: "none",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#C7DCFF",
    },
    "&.selected": {
      backgroundColor: "#3675dc",
      color: "#fff",
    },
  },
  parentButton: {
    backgroundColor: "#F8E8FE", // Pastel pink
    color: "#333",
    borderRadius: theme.shape.borderRadius * 3,
    textTransform: "none",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#EDD1FD",
    },
    "&.selected": {
      backgroundColor: "#a44cd73b",
      color: "#fff",
    },
  },
  teacherButton: {
    backgroundColor: "#ECFDE8", // Pastel green
    color: "#333",
    borderRadius: theme.shape.borderRadius * 3,
    textTransform: "none",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#D8FBCF",
    },
    "&.selected": {
      backgroundColor: "#6ae04e",
      color: "#fff",
    },
  },
  textFieldUnderline: {},
  textField: {},
  formButtons: {
    marginTop: theme.spacing(2),
  },
  loginLoader: {
    marginLeft: theme.spacing(2),
  },
  forgetButton: {
    marginTop: theme.spacing(1),
  },
}));

function Login(props) {
  const classes = useResponsiveStyles();
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showPasswordDialogReset, setShowPasswordDialogReset] = useState(false);

  const history = useHistory();

  // global
  var userDispatch = useUserDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [roleValue, setRoleValue] = useState("PARENT");

  // OTP dialog states
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [otpDialogOpenReset, setOtpDialogOpenReset] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpReset, setOtpReset] = useState("");
  const [newPasswordReset, setNewPasswordReset] = useState("");
  const [oldPasswordReset, setOldPasswordReset] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpSentReset, setOtpSentReset] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(30);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // Role click
  const handleRoleClick = (role) => {
    setRoleValue(role);
  };
  const handlePasswordChangeConfirm = () => {
    // setOtpDialogOpen(true);
    // setOtpSent(true)
    setOtpDialogOpenReset(true); 
   
    //history.push("/change-password"); // Redirect to password reset page
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (confirmPassword && e.target.value !== confirmPassword) {
      setError("Passwords do not match!");
    } else {
      setError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (password && e.target.value !== password) {
      setError("Passwords do not match!");
    } else {
      setError("");
    }
  };
  
  // Handle OTP Timer
  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  // Send OTP
  const handleSendOtp = async () => {
    try {
      await CategoryServices.forgetUserPassword({ mobile: mobile });
      setOtpSent(true);
      setResendEnabled(false);
      setTimer(30);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
};

const handleMouseDownPassword = (event) => {
    event.preventDefault();
};
  // Resend OTP
  const handleResendOtp = async () => {
    try {
      await CategoryServices.forgetUserPassword({ mobile: mobile });
      setResendEnabled(false);
      setTimer(30);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };
  const resetDefaultPassword= async () => {
    try {
      await CategoryServices.resetDefaultPassword({ mobile: mobile });
      setResendEnabled(false);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  }
  // Update password
  const handleUpdatePassword = async () => {
    try {
      await CategoryServices.updateUserPassword({ mobile, otp, newPassword });
      alert("Password updated successfully.");
      setOtpDialogOpen(false);
     // props.history.push("/montessori/login");
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };
  const handleUpdatePasswordReset = async () => {
    try {
      if(!password){
alert('please enter password')
return
      }
      if(!confirmPassword)
        {
          alert('please enter password')
          return
                }
       if(confirmPassword !== password){
       alert(
        'password and confim password not matching'
       )
       return
       }
      const confirmPasswords =await CategoryServices.resetDefaultPassword({ mobile:loginValue,  password:confirmPassword,  });
      console.log(confirmPasswords)
      alert(confirmPasswords.message);
      setOtpDialogOpenReset(false);
      if(confirmPasswords.status){
        history.push('/app/assignment') ;
      }
     // props.history.push("/montessori/login");
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };
  return (
    <Grid container className={classes.container}>
      {/* Main Card Section */}
      <Box className={classes.mainBox} >
        <Grid container spacing={3} alignItems="center">
          {/* Left Side - Login Form */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Welcome to Montestory
            </Typography>

            {/* Role Buttons */}
            <Grid container spacing={3} alignItems="center" style={{ paddingRight: "20px" }}>
              {/* Parent Button */}
              <Grid item xs={6} sm={3}>
                <Button
                  className={`${classes.parentButton} ${
                    roleValue === "PARENT" ? "selected" : ""
                  }`}
                  onClick={() => handleRoleClick("PARENT")}
                >
                  <Box className={classes.icon}>
                    <img
                      src="/images/Parent icon.svg"
                      alt="Parent Icon"
                      className={classes.iconImage}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    style={{ color: "black", fontSize: "15px", marginTop: "-7px" }}
                  >
                    Parent
                  </Typography>
                </Button>
              </Grid>

              {/* Teacher Button */}
              <Grid item xs={6} sm={3}>
                <Button
                  className={`${classes.teacherButton} ${
                    roleValue === "TEACHER" ? "selected" : ""
                  }`}
                  onClick={() => handleRoleClick("TEACHER")}
                >
                  <Box className={classes.icon}>
                    <img
                      src="/images/Teacher icon.svg"
                      alt="Teacher Icon"
                      className={classes.iconImage}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    style={{ color: "black", fontSize: "15px", marginTop: "-7px" }}
                  >
                    Teacher
                  </Typography>
                </Button>
              </Grid>

              {/* School Button */}
              <Grid item xs={6} sm={3}>
                <Button
                  className={`${classes.studentButton} ${
                    roleValue === "SCHOOLE" ? "selected" : ""
                  }`}
                  onClick={() => handleRoleClick("SCHOOLE")}
                >
                  <Box className={classes.icon}>
                    <img
                      src="/images/Admin icon.svg"
                      alt="School Icon"
                      className={classes.iconImage}
                      style={{ width: "16px" }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    style={{ color: "black", fontSize: "15px", marginTop: "-7px" }}
                  >
                    School
                  </Typography>
                </Button>
              </Grid>

              {/* Admin Button */}
              <Grid item xs={6} sm={3}>
                <Button
                  className={`${classes.teacherButton} ${
                    roleValue === "SUPERADMIN" ? "selected" : ""
                  }`}
                  onClick={() => handleRoleClick("SUPERADMIN")}
                >
                  <Box className={classes.icon}>
                    <ChildCareSharp />
                  </Box>
                  <Typography
                    variant="body1"
                    style={{ color: "black", fontSize: "15px", marginTop: "-7px" }}
                  >
                    Admin
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            {/* Login Fields */}
            <TextField
              id="email"
              variant="outlined"
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Mobile number (Login Name)"
              type="email"
              fullWidth
            />
              
            <TextField
              id="password"
              variant="outlined"
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
               
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            />

            {/* Login Buttons */}
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button 
                  disabled={loginValue.length === 0 || passwordValue.length === 0}
                
                  onClick={() =>
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                      roleValue,
                      handlePasswordChangeConfirm,
                      
                    )
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{backgroundColor:'#0f3b62', color:"white"}}
                >
                  Login
                </Button>
              )}

              <Button
                color="primary"
                size="large"
                style={{ fontWeight: "bold", fontSize:"smaller" }}
                className={classes.forgetButton}
                onClick={() => {
                  setOtpDialogOpen(true);
                  setOtpSent(false)}}
              >
                Forgot Password?
              </Button>
            </div>

            {/* OTP Dialog */}
            <Dialog
              open={otpDialogOpen}
              onClose={() => setOtpDialogOpen(false)}
              style={{ margin: "50px" }}
              PaperProps={{
                style: { width: "350px" }, // Set desired width
              }}
            >
              <DialogTitle style={{fontSize:"smaller"}}>Forget Password</DialogTitle>
              <DialogContent>
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  fullWidth
                  margin="normal"
                  disabled={otpSent}
                />

                {otpSent && (
                  <>
                    <TextField
                      label="Enter OTP"
                      variant="outlined"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="New Password"
                      type="password"
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      fullWidth
                      margin="normal"
                    />

                    {/* Update Password Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdatePassword}
                      fullWidth
                      style={{ marginTop: 16 }}
                    >
                      Update Password
                    </Button>

                    {/* Resend OTP Section */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Button variant="text" onClick={handleResendOtp} disabled={!resendEnabled}>
                        Resend OTP
                      </Button>
                      {!resendEnabled && (
                        <Typography variant="body2" color="textSecondary">
                          Resend available in {timer} sec
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOtpDialogOpen(false)} color="secondary">
                  Cancel
                </Button>
                {!otpSent && (
                  <Button variant="contained" color="primary" onClick={handleSendOtp}>
                    Send OTP
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Grid>
          <Dialog
              open={otpDialogOpenReset}
              onClose={() => setOtpDialogOpenReset(false)}
              style={{ margin: "50px" }}
              PaperProps={{
                style: { width: "350px" }, // Set desired width
              }}
            >
              <DialogTitle style={{fontSize:"smaller"}}>Reset Password</DialogTitle>
              <DialogContent>
               

              
                  <>
                    {/* <TextField
                      label="Mobile"
                       type="text"
                      variant="outlined"
                      // value={oldPasswordReset}
                      // onChange={(e) => setOldPasswordReset(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Update Password"
                                            variant="outlined"
                      // value={newPasswordReset}
                      // onChange={(e) => setNewPasswordReset(e.target.value)}
                      fullWidth
                      margin="normal"
                    /> */}

                    {/* Update Password Button */}
                    <TextField
        label="Password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        fullWidth
        value={password}
        onChange={handlePasswordChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
       <TextField
        label="Confirm Password"
        variant="outlined"
        type={showConfirmPassword ? "text" : "password"}
        fullWidth
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        margin="normal"
        error={!!error}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdatePasswordReset}
                      fullWidth
                      style={{ marginTop: 16 }}
                    >
                      Update Password
                    </Button>

                    {/* Resend OTP Section */}
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Button variant="text" onClick={handleResendOtp} disabled={!resendEnabled}>
                        Resend OTP
                      </Button>
                      {!resendEnabled && (
                        <Typography variant="body2" color="textSecondary">
                          Resend available in {timer} sec
                        </Typography>
                      )}
                    </Box> */}
                  </>
               
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOtpDialogOpenReset(false)} color="secondary">
                  Cancel
                </Button>
                {/* {!otpSent && (
                  <Button variant="contained" color="primary" onClick={handleSendOtp}>
                    Send OTP
                  </Button>
                )} */}
              </DialogActions>
            </Dialog>
          {/* Right Side - Illustration */}
          <Grid item xs={12} md={6} style={{ textAlign: "center", backgroundColor:"black"}} className="right-section">
            <div>
              <div className="illustration">
                <img
                  src="/images/zzga3cb5 (1).png"
                  alt="Kids Illustration"
                  style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }}
                />
                <h3>Learning Made Fun!</h3>
                <p>Join our vibrant community of learners, parents, and teachers!</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      {/* "How Montestory Works" Section */}
      <Container sx={{ py: 8 }}>
        <br></br>
        <br></br>
        <Typography variant="h4" align="center" gutterBottom>
          How Montestory Works
        </Typography>
        <br></br>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Step 1 */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#FFEACF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: 2,
                }}
              >
                <Typography variant="h6" color="primary">
                  1
                </Typography>
              </Box>
              <Typography variant="h6" gutterBottom>
                Register Your School
              </Typography>
              <Typography variant="body2">
                Create an account and set up your school profile.
              </Typography>
            </Box>
          </Grid>

          {/* Step 2 */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#D6F7D9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: 2,
                }}
              >
                <Typography variant="h6" color="primary">
                  2
                </Typography>
              </Box>
              <Typography variant="h6" gutterBottom>
                Add Teachers & Students
              </Typography>
              <Typography variant="body2">
                Manage classroom details and student information.
              </Typography>
            </Box>
          </Grid>

          {/* Step 3 */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#CDE6FF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: 2,
                }}
              >
                <Typography variant="h6" color="primary">
                  3
                </Typography>
              </Box>
              <Typography variant="h6" gutterBottom>
                Track Progress
              </Typography>
              <Typography variant="body2">
                Monitor activities and communicate with parents.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        {/* Featured Tools */}
        <Typography variant="h4" align="center" sx={{ mt: 8 }} gutterBottom>
          Featured Tools
        </Typography>
     <br></br>
   
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Activities */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
                height: "100%",
              }}
            >
              <img
                src="/images/svg.svg"
                alt="Activities"
                style={{ width: "25px", marginBottom: "8px" }}
              />
              <Typography variant="h6" gutterBottom>
                Activities
              </Typography>
              <Typography variant="body2">
                Oversee tasks, attendance records, and performance metrics to
                facilitate ongoing enhancement.
              </Typography>
            </Box>
          </Grid>

          {/* Weekly Planner */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
                height: "100%",
              }}
            >
              <img
                src="/images/i.svg"
                alt="Weekly Planner"
                style={{ width: "25px", marginBottom: "8px" }}
              />
              <Typography variant="h6" gutterBottom>
                Weekly Planner
              </Typography>
              <Typography variant="body2">
                Organize activities and manage tasks efficiently.
              </Typography>
            </Box>
          </Grid>

          {/* Kids Own Stories */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
                height: "100%",
              }}
            >
              <img
                src="/images/svg-1.svg"
                alt="Kids Own Stories"
                style={{ width: "25px", marginBottom: "8px" }}
              />
              <Typography variant="h6" gutterBottom>
                Kids Own Stories
              </Typography>
              <Typography variant="body2">
                Stay engaged with your child’s activities and creative journey.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Footer */}
      <Box
        component="footer" style={{marginTop:"3rem"}}
        sx={{
          backgroundColor: "#0D3B66",
          color: "#fff",
          pt: 4,
          pb: 2,
          width: "100%",
        }}
      >
      
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Logo + Tagline */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src="https://artistimages.blob.core.windows.net/montestory/logo 2.svg"
                  alt="Logo"
                  style={{ width: "85px" }}
                />
              </Box>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                Smart school management for young learners
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem" }}>
                Quick Links
              </Typography>
              <br></br>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Link href="#/montessori/schoolregistration" underline="hover" color="inherit">
                  School Registration
                </Link>
                <Link href="#/montessori/login" underline="hover" color="inherit">
                  Login
                </Link>
              </Box>
            </Grid>

            {/* Support */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem" }}>
                Support
              </Typography>
              <br></br>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Link href="#/montessori/privacy" underline="hover" color="inherit">
                  Privacy Policy
                </Link>
                <Link href="#/montessori/term" underline="hover" color="inherit">
                  Terms of Service
                </Link>
              </Box>
            </Grid>

            {/* Contact */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem" }}>
                Contact
              </Typography>
              <br></br>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography variant="body2">amar@srshta.com</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon sx={{ mr: 1 }} />
                <Typography variant="body2">+91 8247625893</Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              mt: 4,
              pt: 2,
              textAlign: "center",
            }}
          >
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              © 2025 Montestory. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Grid>
  );
}

export default withRouter(Login);
