import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell,Checkbox,
     FormControlLabel,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ExportExcel from "../../Excelexport";
import { Card, Box } from "@material-ui/core";
import ActivityService from "./Locality/Service/activityService";
import ActivityTabelService from "./Locality/Service/activityTabelService";
import SubActivityService from "./Locality/Service/subActivityService";
import StudentService from "./Locality/Service/studentService"
import SuperActivityService from "./Locality/Service/superActivityService"
import * as Yup from 'yup';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AddClassService from "./Locality/Service/addClassService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#0f3b62ad",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function ActivityExpected() {
    const tableHeaders = ['Class', 'Student Name', 'Area Of Work', 'List Of Activities', 'Exercise', 'Edit', 'Delete', 'Add More'];
    const classes = useStyles();
    const [activityList, setActivityList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [subActivityList, setSubActivityList] = useState([]);
    const [classNameList, setClassNameList] = useState([]);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [activityIdList, setActivityIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [addClassList, setAddClassList] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [result, setResult] = useState([]);
    const [endDate, setEndDate] = useState('');
    var [studentId, setStudentId] = useState("");
    const [subActivityIdList, setSubActivityIdList] = useState([]);
    const [addActivityList, setAddActivityList] = useState([]);
    const [addSuperActivityList, setAddSuperActivityList] = useState([]);
    const today = new Date();
    var [classId, setClassId] = useState("");

    const getWeekStartEnd = (date) => {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        const endOfWeek = new Date(date);
        endOfWeek.setDate(endOfWeek.getDate() + (5 - endOfWeek.getDay()));

        let startDay = startOfWeek.getDate();
        let startMonth = startOfWeek.getMonth() + 1;
        let startYear = startOfWeek.getFullYear();
        if (startDay < 10) {
            startDay = '0' + startDay;
        }

        if (startMonth < 10) {
            startMonth = `0${startMonth}`;
        }
        let format = startYear + "-" + startMonth + "-" + startDay;

        let endDay = endOfWeek.getDate();
        let endMonth = endOfWeek.getMonth() + 1;
        let endYear = endOfWeek.getFullYear();
        if (endDay < 10) {
            endDay = '0' + endDay;
        }

        if (endMonth < 10) {
            endMonth = `0${endMonth}`;
        }

        let format1 = endYear + "-" + endMonth + "-" + endDay;

        return { start: format, end: format1 };
    }
    const date = new Date();
    const week = getWeekStartEnd(date);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(1000);
    const year = today.getFullYear();
    //   const today = dayjs();
    const [activity, setActivity] = useState({
        classId: '',
        sfd: '',
        // studentId:'',
        studentId: [],
        superActivityId: '',
        activityId: '',
        subActivityId: '',
        remarks: '',

    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }

    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const validationSchema = Yup.object().shape({
        // classId: Yup.string().required('Class Name is required'),
        studentId: Yup.array().required(' required'),
        activityId: Yup.string().required('ActivityName is required'),
        subActivityId: Yup.string(),
        // academicYear:Yup.string(),
        remarks: Yup.string(),
        planning: Yup.string(),
        sfd: Yup.string(),
        date: Yup.string(),
    });
    useEffect(() => {
        // getActivityList();
        getAddClassList();
        // getStudentActivityList();
        // getStudentList()
        // getSuperActivityList();
        setStartDate(week.start);
        setEndDate(week.end);

        onSubmit();
        return () => {
            setSubActivityList([]);
            setActivityIdList([]);
            setActivityList([]);
            setAddClassList([]);
            setStudentList([]);
            setAddSuperActivityList([]);
            // setClassNameList([]);
        }
    }, []);

    const getSuperActivityList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getsupActList = { classId: event.target.value };

        SuperActivityService.getAllSuperActivityById(userDetails.schooleId, getsupActList).then((res) => {

            setAddSuperActivityList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const handleOpen = (status) => {
        if (status) {
            setActivity({
                sfd: '',
                // studentId:'',
                studentId: [],
                superActivityId: '',
                activityId: '',
                subActivityId: '',
                remarks: '',
            })
        }

        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const newstartDate = startDate ? startDate : week.start;
        const newendDate = endDate ? endDate : week.end;

        const keys = { "schooleId": userDetails.schooleId, "isFuturePlanning": true, "studentId": studentId, 'classId': classId, "startDate": newstartDate, "endDate": newendDate, "status": true, }
        ActivityService.findActivityList(keys).then((res) => {
            excelExport(res);
            // setClassValue("");
            setActivityList(res);
            // setStudentList(res);
        }).catch((err) => {
            // setError(err.message);
        });

    };
    // const getActivityList = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     ActivityTabelService.getAllActivityTabel(userDetails.schooleId).then((res) => {
    //         setAddActivityList(res);
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
    const getActivityList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getsubActList = {
            schooleId: userDetails.schooleId,
            classId: formik.values.classId,
            superActivityId: event.target.value
        };

        ActivityTabelService.getAllActivityTabeBySuperActivityId(getsubActList).then((res) => {
            setAddActivityList(res);
            if (obj) {
                setActivity(obj);
            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const excelExport = (res) => {
        const result = res.map((response) => {

            return {
                "Student Name": response.studentId ? `${response.studentId.firstName} ${response.studentId.lastName}` : '',
                "Class Name": response.classId ? response.classId.className : '',

                "Area Of Work": response.superActivityId ? response.superActivityId.superActivityName : '',
                "List Of Activities": response.activityId ? response.activityId.activityName : '',
                "Exercise": response.subActivityId ? response.subActivityId.subActivityName : '',
                "Notes": response.remarks,
                "Date": response.sfd,
            }

        })
        setResult(result);
    };
    const getStudentList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getStuList = { classId: event.target.value };


        StudentService.getAllStudentById(userDetails.schooleId, getStuList
        ).then((res) => {

            const studentDetails = res.map(res => {
                return { _id: res._id, studentName: `${res.firstName} ${res.lastName}`, status: true };
            });

            setStudentList(studentDetails);

            if (obj) {
                setActivity(obj);
            }
        }).catch((err) => {
            // setError(err.message);
        });
    }

    const getStudentActivityList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        ActivityTabelService.getStudentActivity(userDetails.schooleId, true).then((res) => {
            setActivityList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getSubActivityList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getsubActList = { schooleId: userDetails.schooleId, activityId: event.target.value };
        SubActivityService.getAllSubActivityByActivityId(getsubActList).then((res) => {
            setSubActivityList(res);
            if (obj) {
                setActivity(obj);
            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getAddClassList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
            if (userDetails.role === "TEACHER") {
                res = res.filter(res => res._id === userDetails.classId);
            }
            setAddClassList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getClassNameList = (event) => {
        AddClassService.getAddClassNameById({ className: event.target.value }).then((res) => {
            setClassNameList(res);
        }).catch((err) => {
            setError(err.message);
        });
    }
    const editActivity = (useractivitys, status) => {
        const obj = JSON.parse(JSON.stringify(useractivitys, status));
        obj.classId = useractivitys.classId ? useractivitys.classId._id : '';
        obj.studentId = Array.isArray(useractivitys.studentId)
            ? useractivitys.studentId.map(student => student._id)
            : useractivitys.studentId
        // obj.studentId = useractivitys.studentId ? useractivitys.studentId._id : '';
        obj.superActivityId = useractivitys.superActivityId ? useractivitys.superActivityId._id : '';
        obj.activityId = useractivitys.activityId ? useractivitys.activityId._id : '';
        obj.subActivityId = useractivitys.subActivityId ? useractivitys.subActivityId._id : '';
        getSuperActivityList({ target: { value: obj.classId } }, obj);
        getStudentList({ target: { value: obj.classId } }, obj);
        getSubActivityList({ target: { value: obj.activityId } }, obj);
        getActivityList({ target: { value: obj.superActivityId } }, obj);
        getAddClassList({ target: { value: obj.superActivityId } }, obj)
        //getStudentActivityList(useractivitys.classId);
        if (status) {
            delete obj._id;
        }
        setActivity(obj);
        handleOpen(false)
    }
    const deleteActivity = (activitydelete) => {
        if (activitydelete) {
            ActivityService.deleteActivity(activitydelete).then((res) => {

                onSubmit()
            }).catch((err) => {
            });
        }
    };
    const formik = useFormik({
        initialValues: activity,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.schooleId = userDetails.schooleId;
            values.authorizedPerson = userDetails.email
            values.isFuturePlanning = true;
            values.key = "Future Plane"
            if (activity._id) {
                ActivityService.upadeActivity(values).then((res) => {
                    handleClose();
                    // getActivityList();
                    onSubmit()
                    resetForm()
                    alert("Activity Updated Successfully.");
                }).catch((err) => {
                });
            }


            else {
                let currentYear = new Date().getFullYear();
                let nextYear = new Date().getFullYear() + 1;
                console.log(currentYear + "-" + nextYear);
                values['academicYear'] = currentYear + "-" + nextYear;
                if (values.sfd) {
                    values['sfd'] = new Date(values['sfd']);
                }
                ActivityService.creteActivity(values).then((res) => {

                    // getActivityList();
                    onSubmit()

                    resetForm();
                    handleClose();
                    alert(" Activity Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {

                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (

        <>
            <PageTitle title=" Weekly Plan" button={<Button
                variant="contained" onClick={() => handleOpen(true)}
                size="medium"
                color="secondary" style={{ backgroundColor: 'rgb(15 59 98)' }}> Add Activity
            </Button>} />
            <Card sx={{ maxWidth: 345 }}>
                <Box style={{ margin: 15 }}  >

                    <Grid container spacing={2} columns={12}  >
                        <Grid item xs={6} sm={6} md={2} >
                            <FormControl className={classes.formControl} style={{ width: 170 }}
                            >
                                <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="classId"
                                    label="selectClass"
                                    // onChange={e => {  getStudentList(e) }}
                                    onChange={e => { setClassId(e.target.value); getStudentList(e) }}
                                    value={classId}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    {addClassList.map(({ _id, className }) => (
                                        <MenuItem key={_id} value={_id}>{className}

                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} >
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="studentName">Student Name</InputLabel>
                                <Select
                                    labelId="studentName"
                                    id="studentName"
                                    label="Student Name"
                                    name="studentId"
                                    value={studentId}
                                    onChange={e => { setStudentId(e.target.value) }}
                                // error={formik.touched.studentId && Boolean(formik.errors.studentId)}
                                // helperText={formik.touched.studentId && formik.errors.studentId}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {studentList.map(({ index, _id, studentName }) => (
                                        <MenuItem key={index} value={_id}>{studentName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <form className={classes.container} noValidate>
                                <TextField InputProps={{ style: { width: 150 } }}
                                    id="dob"
                                    name="dob"
                                    label="Start Date"
                                    type="date"
                                    value={startDate}
                                    onChange={e => { getStudentList(e.target.value); setStartDate(e.target.value) }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} >
                            <TextField InputProps={{ style: { width: 120 } }}
                                id="dob"
                                name="dob"
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={e => { setEndDate(e.target.value) }}
                                // defaultValue="2017-05-24"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} >
                            <Button style={{ backgroundColor: 'rgb(15 59 98)', color: 'white' }}
                                type="button"

                                onClick={() => onSubmit()} variant="contained"
                            >

                                Search</Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}   >

                            <ExportExcel excelData={result} fileName={'Student Activity'} />


                        </Grid>
                    </Grid>

                </Box>
            </Card>
            <br></br>
            <Grid container spacing={4}>
                <Grid item xs={12}>

                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {activityList.slice(pg * rpg, pg * rpg + rpg).map((activitydetails) => (
                                    <TableRow key={activitydetails._id}>

                                        <TableCell className="pl-3 fw-normal" >{activitydetails.classId ? activitydetails.classId.className : ''}</TableCell>

                                        <TableCell className="pl-3 fw-normal">
                                            {activity.isEventForAllStudent ? "All" : activity.studentId?.map((student, index) => (
                                                <span key={student._id || index}>
                                                    {student.firstName} {student.lastName}
                                                    {index < activity.studentId.length - 1 ? ", " : ""}
                                                </span>
                                            ))}

                                        </TableCell>

                                        <TableCell className="pl-3 fw-normal" >{activitydetails.superActivityId ? activitydetails.superActivityId.superActivityName : ''}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{activitydetails.activityId ? activitydetails.activityId.activityName : ''}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{activitydetails.subActivityId ? activitydetails.subActivityId.subActivityName : ''}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{activitydetails.sfd}</TableCell>                                   */}
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editActivity(activitydetails)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteActivity(activitydetails._id)} />
                                        </TableCell>
                                        <TableCell>
                                            <AddIcon style={{ cursor: 'pointer' }} onClick={() => editActivity(activitydetails, true)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[1000, 10000, 1000000]}
                            count={activityList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Activity</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <TextField InputProps={{ style: { width: 253 } }}
                            id="sfd"
                            name="sfd"
                            autoFocus
                            label="Select Future Date"
                            type="date"
                            value={formik.values.sfd}
                            onChange={formik.handleChange}
                            error={formik.touched.sfd && Boolean(formik.errors.sfd)}
                            helperText={formik.touched.sfd && formik.errors.sfd}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl className={classes.formControl} fullWidth="true"
                        >
                            <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="classId"
                                label="selectClass"
                                value={formik.values.classId}
                                onChange={e => { formik.handleChange(e); getStudentList(e); getSuperActivityList(e) }}
                                // onChange={formik.handleChange}

                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                helperText={formik.touched.classId && formik.errors.classId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}

                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="studentName-label">Student Name</InputLabel>
                            <Select
                                labelId="studentName-label"
                                id="studentName-select"
                                label="Student Name"
                                name="studentId"
                                multiple
                                value={formik.values.studentId} // make sure this is an array in Formik's initialValues
                                onChange={formik.handleChange}
                                // Customize how the selected values are displayed in the input
                                renderValue={(selected) => {
                                    // Find student names for the selected IDs
                                    const selectedNames = studentList
                                        .filter(({ _id }) => selected.includes(_id))
                                        .map(({ studentName }) => studentName);
                                    return selectedNames.join(", ");
                                }}
                            >
                                {studentList.map(({ _id, studentName }) => (
                                    <MenuItem key={_id} value={_id}>
                                        {studentName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>

                            {formik.values.isEventForAllStudent}
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox name="isEventForAllStudent" id="terms"
                                            value={formik.values.isEventForAllStudent}
                                            checked={formik.values.isEventForAllStudent}
                                            onChange={formik.handleChange}
                                            error={formik.touched.isEventForAllStudent && Boolean(formik.errors.isEventForAllStudent)}
                                            helperText={formik.touched.isEventForAllStudent && formik.errors.isEventForAllStudent}
                                        />
                                    }
                                    label="For All Class Students"
                                />
                            </Grid>


                        </div>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Area Of Work</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="superActivityName"
                                label="Area Of Work "
                                name="superActivityId"
                                onChange={e => { formik.handleChange(e); getActivityList(e) }}
                                value={formik.values.superActivityId}
                                error={formik.touched.superActivityId && Boolean(formik.errors.superActivityId)}
                                helperText={formik.touched.superActivityId && formik.errors.superActivityId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addSuperActivityList.map(({ _id, superActivityName }) => (

                                    <MenuItem key={_id} value={_id}>{superActivityName}
                                        {/* <Checkbox checked={formik.values.categoryId.indexOf(parent) > -1} /> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">List Of Activities</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="activityName"
                                label="List Of Activities"
                                name="activityId"
                                onChange={e => { formik.handleChange(e); getSubActivityList(e) }}
                                value={formik.values.activityId}
                                error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                                helperText={formik.touched.activityId && formik.errors.activityId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addActivityList.map(({ _id, activityName }) => (

                                    <MenuItem key={_id} value={_id}>{activityName}
                                        {/* <Checkbox checked={formik.values.categoryId.indexOf(parent) > -1} /> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="subActivityName">Exercise</InputLabel>
                            <Select
                                labelId="subActivityName"
                                id="subActivityId"
                                label="Exercise"
                                name="subActivityId"
                                value={formik.values.subActivityId}
                                onChange={formik.handleChange}
                                error={formik.touched.subActivityId && Boolean(formik.errors.subActivityId)}
                                helperText={formik.touched.subActivityId && formik.errors.subActivityId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {subActivityList.map(({ _id, subActivityName }) => (
                                    <MenuItem key={_id} value={_id}>{subActivityName}
                                        {/* <Checkbox checked={formik.values.categoryId.indexOf(parent) > -1} /> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="remarks"
                            name="remarks"
                            label="Notes"
                            type="text"
                            variant="standard"
                            value={formik.values.remarks}
                            onChange={formik.handleChange}
                            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                            helperText={formik.touched.remarks && formik.errors.remarks}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>

        </>
    );
}


