import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell, Card, Box,
} from "@material-ui/core";
import SuperActivityService from "./Locality/Service/superActivityService"
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SubActivityService from "./Locality/Service/subActivityService";
import ActivityTabelService from "./Locality/Service/activityTabelService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { styled } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AddClassService from "./Locality/Service/addClassService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#0f3b62ad",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function SubActivity() {
    const tableHeaders = ['Class', 'List Of Activities', 'Exercise', 'Edit', 'Delete'];
    const classes = useStyles();
    const [filterActivity, setfilterActivity] = useState('');
    const [filterExercise, setExercise] = useState('');
    const [filterExerciseList, setExerciseList] = useState([]);
    const [subActivitylList, setSubActivityList] = useState([]);
    const [temSubActivitylList, setTempSubActivityList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [addActivityList, setAddActivityList] = useState([]);
    const [subActivityIdList, setSubActivityIdList] = useState([]);
    const [addSuperActivityList, setAddSuperActivityList] = useState([]);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(1000);
    const [addClassList, setAddClassList] = useState([]);
    const [subActivity, setSubActivity] = useState({
        classId: '',
        superActivityId: '',
        activityId: '',

        subActivityName: '',
        upload: '',
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const validationSchema = Yup.object().shape({
        // classId: Yup.string().required('Class Name is required'),
        subActivityName: Yup.string(),
        upload: Yup.string(),
    });
    useEffect(() => {
        getSubList();
        // getActivityList()
        getAddClassList();
        return () => {
            setSubActivityIdList([]);
            setActivityList([]);
            setSubActivityList([]);
            setTempSubActivityList([]);
            setAddSuperActivityList([]);
        }
    }, []);
    const handleOpen = (status) => {
        if (status) {
            setSubActivity({
                subActivityName: '',
            })
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getSubList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        SubActivityService.getAllSubActivity(userDetails.schooleId).then((res) => {

            if (res) {
                setSubActivityList(res);
                setTempSubActivityList(res);
                const setValues = res.map(result => {
                    return { name: result.subActivityName, value: result._id };
                });
                setExerciseList(setValues);

            }

        }).catch((err) => {

        });
    }
    const getActivityList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getsubActList = {
            schooleId: userDetails.schooleId,
            classId: formik.values.classId,
            superActivityId: event.target.value
        };

        ActivityTabelService.getAllActivityTabeBySuperActivityId(getsubActList).then((res) => {
            setAddActivityList(res);
            if (obj) {
                setSubActivity(obj);
            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getAddClassList = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
    //         setAddClassList(res);
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
    const getAddClassList = (event) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
            if (userDetails.role === "TEACHER") {
                res = res.filter(res => res._id === userDetails.classId);
            }
            setAddClassList(res);
            if (res.length > 0) {
                //getActivityList()

            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const editSubActivity = (sub) => {
        const obj = JSON.parse(JSON.stringify(sub));
        obj.superActivityId = sub.superActivityId ? sub.superActivityId._id : '';
        obj.activityId = sub.activityId ? sub.activityId._id : '';
        // obj.superActivityId = sub.superActivityId ? sub.superActivityId._id : '';
        // obj.activityId = sub.activityId ? sub.activityId._id : '';
        obj.classId = sub.classId ? sub.classId._id : '';

        // tabel.classId = activity.classId ? activity.classId._id :'';
        // getActivityList(obj)
        getSuperActivityList({ target: { value: obj.classId } });
        getActivityList({ target: { value: obj.superActivityId } })

        setSubActivity(obj)
        handleOpen(false)
    }
    const deleteSubActivity = (subdelete) => {
        if (subdelete) {
            SubActivityService.deleteSubActivity(subdelete).then((res) => {
                getSubActivityList();
            }).catch((err) => {
            });
        }
    };
    const getSubActivityList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        SubActivityService.getAllSubActivity(userDetails.schooleId).then((res) => {
            setSubActivityList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const formik = useFormik({
        initialValues: subActivity,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.schooleId = userDetails.schooleId;
            if (subActivity._id) {

                SubActivityService.upadeSubActivity(values).then((res) => {

                    handleClose();
                    getSubActivityList();
                    resetForm()
                    alert("Sub Activity Tabel Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                SubActivityService.creteSubActivity(values).then((res) => {
                    getSubActivityList();
                    resetForm();
                    handleClose();
                    alert(" Sub Activity Tabel Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });
    const getSuperActivityList = (event, obj) => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const getsupActList = { classId: event.target.value };

        SuperActivityService.getAllSuperActivityById(userDetails.schooleId, getsupActList).then((res) => {

            setAddSuperActivityList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const filterActivitys = (event) => {
        const iampig = event ? event._id : '';
        setfilterActivity(iampig);
        if (iampig) {
            const filterValue = temSubActivitylList.filter(act => act.activityId._id === iampig);
            setSubActivityList(filterValue);
            const setValues = filterValue.map(result => {
                return { name: result.subActivityName, value: result._id };
            });
            setExerciseList(setValues);

        } else {
            setSubActivityList(temSubActivitylList);
            const setValues = temSubActivitylList.map(result => {
                return { name: result.subActivityName, value: result._id };
            });
            setExerciseList(setValues);
        }

    }
    // const filterExcDetails = (event) => {
    //     const extId = event?event._id:'';
    //         setExercise(extId);
    //         if (extId) {
    //             const filterValue = temActivitylList.filter(act => act._id === extId);
    //             setTabelList(filterValue);
    //         } else {
    //             setTabelList(temActivitylList);
    //         }

    //     }
    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Get the selected file
    };


    const handleFileUpload = async () => {
        if (!file) {
            setMessage('Please select a file first.');
            return;
        }

        const formData = new FormData();
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        formData.append('excel', file); // Attach the file to the FormData object
        formData.append('schooleId', userDetails.schooleId);

        try {
            SubActivityService.uploadBulkRecords('/subactivity/data/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Let Axios handle the boundary
                },
            }).then((res) => {
                alert("Data Imported Successfully.")
                getSubActivityList();
            }).catch((err) => {
                // setError(err.message);
                alert("Failed to upload check file format.")
            });


        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Failed to upload file.');
        }
    };
    const filterExcDetails = (event) => {
        const sub = event ? event.value : '';
        setExercise(sub);
        if (sub) {
            const filterValue = temSubActivitylList.filter(act => act._id === sub);
            setSubActivityList(filterValue);
        } else {
            if (filterActivity) {
                const filterValue = temSubActivitylList.filter(act => {
                    console.log(act.activityId._id);
                    return act.activityId._id === filterActivity;
                });

                setSubActivityList(filterValue);
            } else {
                setSubActivityList(temSubActivitylList);
            }

        }

    }
    return (
        <>
            <PageTitle title="Exercise"
                button={<Button
                    variant="contained" onClick={() => handleOpen(true)}
                    size="medium"
                    color="secondary" style={{ backgroundColor: 'rgb(15 59 98)' }}> Add Exercise
                </Button>
                }

            />
<Card sx={{ maxWidth: 345 }}>
<Box  style={{margin:"15px"}} >

            <Grid container spacing={2} columns={12} >
                 
                <Grid item xs={6}>
               
                    <Autocomplete
                        id="combo-box-demo"
                        options={addActivityList}
                        getOptionLabel={(option) => option.activityName}

                        onChange={(event, newValue) => {
                            filterActivitys(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Activities" variant="filled" />}
                    />
                </Grid>
                <Grid item xs={6}>


                    <input type="file" onChange={handleFileChange} />
                    <Button style={{ backgroundColor: 'rgb(15 59 98)', color: 'white' }} onClick={handleFileUpload} >
                        Upload Excel
                    </Button>
                    <br></br>
                    <a
                        href="https://artistimages.blob.core.windows.net/montestory/Sample (1).xlsx" // Replace with your file URL
                        download="Sample.pdf" // Suggested name for the file
                    >
                        download Sample Formate
                    </a>
                    <br></br>
                    {message && <p>{message}</p>}


                    {/* <FormControl variant="filled" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label"> Activities</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="activityName"
                                name="activityId"
                                label="Activities"
                                value={filterActivity}
                                onChange={filterActivitys}
                            //  getClassNameList(e) 
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addActivityList.map(({ _id, activityName }) => (
                                    <MenuItem key={_id} value={_id}>{activityName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}

                </Grid>
                {/* <Grid item xs={6}> */}
                {/* <div > */}
                {/* <Autocomplete
      id="combo-box-demo"
      options={filterExerciseList}
      getOptionLabel={(option) => option.name}
      
      onChange={(event, newValue) => {
        filterExcDetails(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Exercise" variant="filled" />}
    /> */}
                {/* <FormControl variant="filled" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label"> Exercise</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="activityName"
                                name="activityId"
                                label=" Activities"
                                value={filterExercise}
                                onChange={filterExcDetails}
                            //  getClassNameList(e) 
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {filterExerciseList.map(({ value, name }) => (
                                    <MenuItem key={value} value={value}>{name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                {/* </div> */}
                {/* </Grid> */}
                
            </Grid>

            </Box>
            </Card >
            <br></br>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>


                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subActivitylList.slice(pg * rpg, pg * rpg + rpg).map((sub) => (
                                    <TableRow key={sub._id}>
                                        <TableCell className="pl-3 fw-normal" >{sub.classId ? sub.classId.className : ''}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{sub.activityId ? sub.activityId.activityName : ''}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{sub.subActivityName}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editSubActivity(sub)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteSubActivity(sub)} />
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[1000, 10000, 100000]}
                            count={subActivitylList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Activity</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                        <FormControl className={classes.formControl} fullWidth="true"
                        >
                            <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="classId"
                                label="selectClass"
                                value={formik.values.classId}
                                onChange={e => { formik.handleChange(e); getSuperActivityList(e) }}

                                // onChange={formik.handleChange}
                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                helperText={formik.touched.classId && formik.errors.classId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}

                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Area Of Work</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="superActivityName"
                                label="Area Of Work "
                                name="superActivityId"
                                // onChange={formik.handleChange}
                                onChange={e => { formik.handleChange(e); getActivityList(e) }}
                                value={formik.values.superActivityId}
                                error={formik.touched.superActivityId && Boolean(formik.errors.superActivityId)}
                                helperText={formik.touched.superActivityId && formik.errors.superActivityId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addSuperActivityList.map(({ _id, superActivityName }) => (

                                    <MenuItem key={_id} value={_id}>{superActivityName}
                                        {/* <Checkbox checked={formik.values.categoryId.indexOf(parent) > -1} /> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">List Of Activities</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="activityName"
                                name="activityId"
                                label="List Of Activities"
                                value={formik.values.activityId}
                                onChange={e => {
                                    formik.handleChange(e);
                                    //  getAddClassList(e)
                                }}

                                // onChange={formik.handleChange}
                                //  getClassNameList(e) 
                                error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                                helperText={formik.touched.activityId && formik.errors.activityId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addActivityList.map(({ _id, activityName }) => (
                                    <MenuItem key={_id} value={_id}>{activityName}
                                        {/* <Checkbox checked={formik.values.categoryId.indexOf(parent) > -1} /> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="subActivityName"
                            name="subActivityName"
                            label="Exercise"
                            type="text"
                            variant="standard"
                            value={formik.values.subActivityName}
                            onChange={formik.handleChange}
                            error={formik.touched.subActivityName && Boolean(formik.errors.subActivityName)}
                            helperText={formik.touched.subActivityName && formik.errors.subActivityName}
                        />
                        <br></br>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>


                </form>
            </Dialog>
        </>
    );
}


