import React, { useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead, Select, TextField,
    TableBody,Card, Box,
    TableCell} from "@material-ui/core";
import ActivityService from "./Locality/Service/activityService";
import SubActivityService from "./Locality/Service/subActivityService";
import AddClassService from "./Locality/Service/addClassService";
import { Grid, } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import StudentService from "./Locality/Service/studentService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#0f3b62ad",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function StudentDetails(props, history) {
    const tableHeaders = ['First Name', 'Last Name',  'Date Of Birth', 'Age','mobileNumber-1', ,'mobileNumber-2','Edit', 'Delete' ];
    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [teacherList, setTeacherList] = useState([]);
    const [classNameList, setClassNameList] = useState([]);
    const [studentList, setStudentList] = useState([]);
          const [isEditMode, setIsEditMode] = useState(false);
          const [editId, setEditId] = useState(null);
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [teacherIdList, setTeacherIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(1000);
       const [file, setFile] = useState(null);
       const [searchTerm, setSearchTerm] = useState("");

       var [studentId, setStudentId] = useState("");
           var [classId, setClassId] = useState("");
           var [searchClassId, setSearchClassId] = useState("");
               const [addClassList, setAddClassList] = useState([]);
    const [addUserPermitionsList, setUserPermitionsList] = useState({studentDetailsEdit:false, studentDetailsDelete:false});
    const [student, setStudent] = useState({
        classId: '',
        teacherName: '',
        email: '',
        subject: '',
        qualification: '',

        address: '',
        mobileNumber: '',
        attendence: '',
        status: '',
    });
    const handleChangePage=(event, newpage) =>{
        setpg(newpage);
    }
  
    const handleChangeRowsPerPage=(event)=> {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    useEffect(() => {
        // getTeacherList();
        // getStudentList();
        getUserPermitionsList();
        getAddClassList();
        return () => {
            setTeacherIdList([]);
            setTeacherList([]);
            setStudentList([]);
            setUserPermitionsList({})
            setAddClassList([]);
        }
    }, []);
    
    const handleOpen = () => {
        props.history.push('/app/studentregistration/add') 
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleFileChangeFile = (e) => {
        setFile(e.target.files[0]); // Get the selected file
    };
    const handleFileUpload = async () => {
        if (!file) {
            setMessage('Please select a file first.');
            return;
        }

        const formData = new FormData();
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        formData.append('excel', file); // Attach the file to the FormData object
        formData.append('schooleId', userDetails.schooleId); 
        
        try {
              SubActivityService.studentdRegister('/subactivity/studentregister/upload',  formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Let Axios handle the boundary
                },
            }).then((res) => {
            alert("Data Imported Successfully.")
            getStudentList();
            }).catch((err) => {
                // setError(err.message);
                alert("Failed to upload check file format.")
            });
         

        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Failed to upload file.');
        }
    };
    const filteredStudents = studentList.filter(student =>{
return  student.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) || student.admissionNumber?.includes(searchTerm);
    }
    
        
    );

    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // second: 'numeric',
          // hour12: true,
    
        });
        return formattedDateTime;
      }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const groupByStudentId = (data) => {
        return data.reduce((acc, item) => {
            const studentId = item.studentId._id;
            if (!acc[studentId]) {
                acc[studentId] = {
                    studentDetails: {
                        _id: studentId,
                        firstName: item.studentId.firstName,
                        lastName: item.studentId.lastName,
                    },
                    activities: [],
                };
            }
            acc[studentId].activities.push(item);
            return acc;
        }, {});
    };
    const onSubmit = data => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
      
        const keys = {
            'classId': searchClassId, 
        }
        StudentService.findStudentList(keys).then((res) => {
          
            const mapResult =   res.map((student)=>{
                return {...student,fullName:`${student.firstName} ${student.lastName} `}
            })
            setStudentList(mapResult);
            // setStudentList(res);
        }).catch((err) => {
            console.error(err)
            // setError(err.message);
        });

    };
    // const getTeacherList = () => {
    //     TeacherService.getAllTeacher().then((res) => {
    //         setTeacherList(res);
    //     }).catch((err) => {
           
    //     });
    // }
    const getUserPermitionsList=()=>{
        const userPermitions= ActivityService.userPermitions();
             setUserPermitionsList(userPermitions);
        }
        const getAddClassList = () => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
                if(userDetails.role==="TEACHER"){
                    res =   res.filter(res=>res._id=== userDetails.classId); 
                }
                setAddClassList(res);
                if(res.length>0){
                    setClassId(res[0]._id)
                    getStudentList({target:{value:res[0]._id}});
                    onSubmit();
                }
                
            }).catch((err) => {
                // setError(err.message);
            });
        }
     
        const getStudentList = () => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            StudentService.getAllStudent(userDetails.schooleId).then((res) => {
             const mapResult =   res.map((student)=>{
                    return {...student,fullName:`${student.firstName} ${student.lastName} `}
                })
                setStudentList(mapResult);
            }).catch((err) => {
                console.error("Error fetching student list:", err);
            });
        };
        // const getStudentList = (event, obj) => {
        //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        //     const getStuList = {  classId:event.target.value}; 
          
    
        //     StudentService.getAllStudentById(userDetails.schooleId, getStuList
        //     ).then((res) => {
               
        //         const studentDetails = res.map(res => {
        //             return { _id: res._id, studentName: `${res.firstName} ${res.lastName}` };
        //         });
                
        //         setStudentList(studentDetails);
    
               
        //     }).catch((err) => {
        //         // setError(err.message);
        //     });
        // }
        
    // const editActivityTabel = (tabel) => {
    //     const obj = JSON.parse(JSON.stringify(tabel));
    //     obj.superActivityId = tabel.superActivityId ? tabel.superActivityId._id : '';
    //     obj.classId = tabel.classId ? tabel.classId._id :'';
    //     // obj.activityId = sub.activityId ? sub.activityId._id : '';
    //     setTabel(obj)
    //     handleOpen()
    // }
    
    const editStudent = (id, student) => {
      
        const obj = JSON.parse(JSON.stringify(student));
        obj.classId = student.classId ? student.classId._id :'';
       
        props.history.push(`/app/studentregistration/${id}`);
        setStudent(obj);
       // handleOpen();
       setIsEditMode(true)
      setEditId(editId ? null : 1)
       
     
    }
    const deleteStudent= (studentdelete) => {
        if (studentdelete) {
            StudentService.deleteStudent(studentdelete).then((res) => {
                getStudentList();
            }).catch((err) => {
            });
        }
    };
    const formik = useFormik({
        initialValues: student,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
        //   StudentService.upadeStudent(values).then((res) => {
        //     alert(" Updated Successfully.");
        //   })
        //     .catch((err) => {
        //       alert(err.response.data.message)
        //     })
        },
      });
      
    return (
        <>
            <PageTitle title="Student Details" button={<Button
                variant="contained"   onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: 'rgb(15 59 98)' }}> Student Registration
            </Button>} />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                   

                            <Grid container spacing={2} columns={12} style={{ margin: 10, }}  >
                                <Grid item xs={6} sm={6} md={4}  >
                                    <FormControl className={classes.formControl} fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="searchClassId"
                                            label="selectClass"


                                            onChange={e => { setSearchClassId(e.target.value); getStudentList(e) }}
                                            value={searchClassId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {addClassList.map(({ _id, className }) => (
                                                <MenuItem key={_id} value={_id}>{className}

                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                           
                               
                                <Grid item xs={6} sm={6} md={4}>
                                    <Button style={{ backgroundColor: 'rgb(15 59 98)', color: 'white' }}
                                        type="button"

                                        onClick={() => onSubmit()} variant="contained"
                                    >
                                        Search</Button>
                                </Grid>
                               
                            </Grid>


                </Box>
            </Card>
                                                    <br></br>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                    <TextField 
                    label="Search" 
                    variant="outlined" 
                    size="small" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
</div>
                   
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>             
                                <StyledTableCell >Admission Number</StyledTableCell>
              
                                <StyledTableCell >First Name</StyledTableCell>
                                <StyledTableCell >Last Name</StyledTableCell>
                                <StyledTableCell >Date Of Birth</StyledTableCell>
                                <StyledTableCell >Class</StyledTableCell>
                                <StyledTableCell >Mother Mobile</StyledTableCell>
                                <StyledTableCell >Father Mobile</StyledTableCell>
                                        { addUserPermitionsList && addUserPermitionsList.studentDetailsEdit ?
                                        <StyledTableCell >Edit</StyledTableCell>: null }
                                          {addUserPermitionsList && addUserPermitionsList.studentDetailsDelete ?
                                        <StyledTableCell >Delete</StyledTableCell>: null }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredStudents.slice(pg * rpg, pg * rpg + rpg).map((student) => (
                                    <TableRow key={student._id}>
                                         <TableCell className="pl-3 fw-normal" >{student.admissionNumber}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{student.firstName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{student.lastName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{dateAndTime(student.dob)}</TableCell>
                                        
                                        <TableCell className="pl-3 fw-normal" >{student.classId ? student.classId.className:'' }</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{student.mobileNumber}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{student.fatherMobileNumber}</TableCell>

                                        { addUserPermitionsList.studentDetailsEdit ? 
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editStudent(student._id, student)} >
                                            </EditIcon >
                                        </TableCell>: null }
                                        { addUserPermitionsList.studentDetailsDelete ?
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteStudent(student._id)} />
                                        </TableCell>: null }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[1000, 50000,  100000]}
                            count={studentList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            
        </>
    );
}


