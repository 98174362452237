import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Typography
} from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

const HeaderView = ({ classes, ...props }) => {
  // State for mobile drawer
  const [mobileOpen, setMobileOpen] = useState(false);

  // Use Material-UI’s theme breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Example navigation links
  const navLinks = [
    { label: "School Registration", path: "/montessori/schoolregistration" },
    { label: "Login", path: "/montessori/login" }
    // Add more links if needed
  ];

  // Drawer content for mobile view
  const drawer = (
    <div className={classes.drawerContainer}>
      <List>
        {navLinks.map((item) => (
          <ListItem
            button
            key={item.label}
            component={Link}
            to={item.path}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ backgroundColor: "white", border: "none", height: "80px",  }}
    >
      <Toolbar className={classes.toolbar} style={{paddingBottom:"40px"}}>
        {/* Logo and Montestory Text */}
        <div className={classes.logoContainer}>
          <img
            src="https://artistimages.blob.core.windows.net/montestory/logo 2.svg"
            alt="logo"
            className={classes.logoImage}
          />
          <Typography variant="h6" className={classes.logoText}>
            Montestory
          </Typography>
        </div>

        {/* Spacer */}
        <div className={classes.grow} />

        {/* Desktop Navigation */}
        {!isMobile && (
          <Grid container alignItems="center" justify="flex-end" spacing={4}>
            {navLinks.map((item) => (
              <Grid item key={item.label}>
                <li
                  style={{
                    display: "block",
                    fontWeight: 600,
                    fontSize: "15px",
                    listStyleType: "none"
                  }}
                >
                  <Link
                    to={item.path}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {item.label}
                  </Link>
                </li>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Mobile Hamburger Icon */}
        {isMobile && (
          <IconButton
            edge="end"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
        )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

const styles = (theme) => ({
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  logoContainer: {
    display: "flex",
    alignItems: "center"
  },
  logoImage: {
    width: "85px",
    height: "auto",
    marginRight: theme.spacing.unit
  },
  logoText: {
    color: "#0F3B62",
    fontWeight: 600,
    fontSize: "2.142rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem" // slightly smaller on very small screens
    }
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing.unit * 2
  },
  drawerContainer: {
    width: 250,
    marginTop: theme.spacing.unit * 8
  },
  drawerPaper: {
    paddingTop: theme.spacing.unit * 2
  }
});

export default withStyles(styles)(HeaderView);
