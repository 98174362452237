import React, { useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';
import { Button, TableRow, Table,TableHead, TableBody, TableCell} from "@material-ui/core";
import ActivityService from "./Locality/Service/activityService";
import { Grid, } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import SchoolService from "./Locality/Service/schoolService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#0f3b62ad",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function SchoolDetails(props, history) {
    const tableHeaders = ['School Name', 'City',   'Contact Person','Contact Number','Contact Email','Tail subscription' ];
    const classes = useStyles();

    const [schoolList, setSchoolList] = useState([]);
    
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [teacherIdList, setTeacherIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [addUserPermitionsList, setUserPermitionsList] = useState({studentDetailsEdit:false, studentDetailsDelete:false});
    const [school, setSchoool] = useState({
        schooleName: '',
        city: '',
        selectCountry: '',
        name: '',
        email: '',

        mobileNumber: '',
        schooleEmail: '',
        schooleContact: '',
        subscription: '',
    });const handleChangePage=(event, newpage) =>{
        setpg(newpage);
    }
  
    const handleChangeRowsPerPage=(event)=> {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    useEffect(() => {
        
        getSchoolList();
    
        return () => {
           
            setSchoolList([]);
           
        }
    }, []);
    const handleOpen = () => {
        props.history.push('/app/studentregistration/add') 
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
   
    const getUserPermitionsList=()=>{
        const userPermitions= ActivityService.userPermitions();
             setUserPermitionsList(userPermitions);
        }
        
    const getSchoolList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        SchoolService.getAllSchoolList().then((res) => {
            setSchoolList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }

    const updateStatus = (status,school) => {
        school.status =status
        SchoolService.upadeSchool(school).then((res) => {
            getSchoolList();
        }).catch((err) => {
            // setError(err.message);
        });
    }
 
    // const deleteStudent= (studentdelete) => {
    //     if (studentdelete) {
    //         StudentService.deleteStudent(studentdelete).then((res) => {
    //             getStudentList();
    //         }).catch((err) => {
    //         });
    //     }
    // };
    const formik = useFormik({
        initialValues: school,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
        //   StudentService.upadeStudent(values).then((res) => {
        //     alert(" Updated Successfully.");
        //   })
        //     .catch((err) => {
        //       alert(err.response.data.message)
        //     })
        },
      });
    return (
        <>
            <PageTitle title="School Details"  />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow> 
                        
                                <StyledTableCell >School Name</StyledTableCell>
                                <StyledTableCell >City</StyledTableCell>
                                <StyledTableCell >Contact Person</StyledTableCell>
                                <StyledTableCell >Contact Number</StyledTableCell>
                                <StyledTableCell >Contact Email</StyledTableCell>
                                <StyledTableCell >School Number</StyledTableCell>
                                <StyledTableCell >School Email</StyledTableCell>
                                {/* <StyledTableCell >Tail subscription</StyledTableCell> */}
                                <StyledTableCell >Staus</StyledTableCell>

                                          {/* {addUserPermitionsList && addUserPermitionsList.studentDetailsDelete ?
                                        <StyledTableCell >Delete</StyledTableCell>: null } */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {schoolList.slice(pg * rpg, pg * rpg + rpg).map((school) => (
                                    <TableRow key={school._id}>
                                        <TableCell className="pl-3 fw-normal" >{school.schooleName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{school.city}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{school.name}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{school.mobileNumber}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{school.email}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{school.schooleContact}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{school.schooleEmail}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{school.subscription}</TableCell> */}
                                        <TableCell className="pl-3 fw-normal">
                                        {school.status?  <Button  variant="contained" color="error"  onClick={() => updateStatus(false, school)}> InActive</Button> : <Button  variant="contained" color="success" onClick={() => updateStatus(true, school)}> Active</Button>}
                                        </TableCell>
                                        {/* { addUserPermitionsList.studentDetailsEdit ? 
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editStudent(student._id, student)} >
                                            </EditIcon >
                                        </TableCell>: null } */}
                                        {/* { addUserPermitionsList.studentDetailsDelete ?
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteStudent(student._id)} />
                                        </TableCell>: null } */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50, 500, 10000]}
                            count={schoolList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            
        </>
    );
}


