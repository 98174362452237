import requests from './httpServices';

const CategoryServices = {
  getAllCategory() {
    return requests.get('/category');
  },
  getByIdCategory(id) {
    return requests.get(`/category/${id}` );
  },
  upadeCategory(body) {
    return requests.put(`/category/${body.id}`,body); 
  },
  creteCategory(body){
    return requests.post('/category/add',body); 
  },
  forgetUserPassword(body){
    return requests.post('/user/forget-user-password',body);
  },
  updateUserPassword(body){
    return requests.post('/user/updateUserPassword',body);
  },
  resetDefaultPassword(body){
    return requests.put('/user/reset-default-password',body);
  },
  //post method for login
  creteUserLogin(body){
    let url = '/user/login';
    // if(body.role==="PARENT"){
    //   url = '/student/login';
    // }else if(body.role==="SCHOOLE"){
    //   url = '/registration/login';
    // }else if(body.role ==="TEACHER"){
    //   url = '/teacher/login';
    // }else if(body.role ==="SUPERADMIN"){
    //   url = '/admin/login';
    // }
    
    return requests.post(url,body);
  },
  deleteCategory(body){
    return requests.delete(`/category/${body._id}`); 
  },

  uploadImage(body){
    return requests.post('/image/upload',body); 
  },
  getUserDetails(){
    return JSON.parse(localStorage.getItem("userDetail")) || {};
  }
};

export default CategoryServices;
