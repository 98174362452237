import React from "react";
import dateFns from "date-fns";
import "./Calender.css";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Container,
  Box,
} from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import EventService from "./Locality/Service/eventService";
import StudentService from "./Locality/Service/studentService";
import StudentCards from "./UserProfileCard";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(props.location.search);
    const currentMonth = queryParams.get("currentMonth");

    this.state = {
      list: [],
        studentList:[],
        studentId:'',
        classId:'',
      currentMonth: new Date(),
      selectedDate: new Date(),
      event: [],
      error: null,
      userselectedDate:''
    };
  }

  handleClicks = (id,date) => {
 
    this.props.history.push("/app/activitydetails/" + id);
  };

  handleClick = (id,date) => {
    console.log(date)
    const selectedDate = dateFns.format(date, "YYYY-MM-DD");


    const userDetails = JSON.parse(localStorage.getItem("userDetail"));

    this.setState({userselectedDate:date});
    EventService.calenderassignmentList({ schooleId: userDetails.schooleId,"studentId":this.state.studentId,"classId":this.state.classId._id,"date":selectedDate })
      .then((res) => {
        this.setState({ event: res, list: this.state.list });
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
  };

  componentDidMount() {
    this.fetchStudentList();
    const date = dateFns.format(this.state.currentMonth, "YYYY-MM-DD");
    //this.getEventList(date);
  }

  getEventList = (date,studentId,classId) => {
    const colors = ["red", "blue", "green", "orange", "purple", "brown"];
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));

    EventService.calenderassignmentList({ schooleId: userDetails.schooleId, classId:classId?classId:this.state.classId,"studentId":studentId ? studentId :this.state.studentId })
      .then((res) => {
        
        const resulatDetails = res.map((result, index) => {
          return {
            ...result,
            eventName: result.eventName ? result.eventName : "",
            date: result.date,
            colorCode: colors[index % colors.length],
          };
        });
        this.setState({
          list: resulatDetails,
          event: { imageURL: [] },
        });
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
  };

  renderHeader() {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <span className="bg">
            <i
              className="fa fa-arrow-circle-left font-color"
              onClick={this.prevMonth}
              aria-hidden="true"
            ></i>
          </span>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end ">
          <span className="bg">
            <i
              className="fa fa-arrow-circle-right font-color"
              onClick={this.nextMonth}
              aria-hidden="true"
            ></i>
          </span>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "ddd";
    const days = [];
    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>,
      );
    }

    return <div className="days row">{days}</div>;
  }

  findEventByDate = (date) => {
    for (const event of this.state.list) {
      if (event.date === date) {
        return {
          eventName: event.eventName,
          id: event._id,
          imageURL: event.imageURL,
          observation: event.observation,
        };
      }
    }
    return null;
  };

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const showDateFormate = "YYYY-MM-DD";
    const customer = "MMMM DD YYYY";

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const datess = dateFns.format(day, showDateFormate);
        const eventDetails = this.findEventByDate(datess);
        const formattedDate = dateFns.format(day, dateFormat);
        const customDateFormat = dateFns.format(day, customer);
        days.push(
          <div  onClick={() => this.handleClick(eventDetails.id,customDateFormat)}
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
          >
            <span className="number">{formattedDate}</span>

            <div className="scrolling">

              {eventDetails && (
                <>
                  <span style={{ fontWeight: "bold" }}><EventIcon  onClick={() => this.handleClick(eventDetails.id,customDateFormat)} style={{ color: "rgb(253 207 58)", fontSize: 40 }}/></span>
                  {/* {eventDetails.imageURL.map((imgUrl, idx) => (
                    <div key={idx} className="truncate">
                      <img
                        src={imgUrl}
                        alt="Event"
                        onClick={() => this.handleClick(eventDetails.id)}
                        style={{ width: "80px", height: "80px", objectFit: "cover" }}
                      />
                    </div>
                  ))} */}
                </>
              )}
            </div>
          </div>,
        );
        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
  };

  nextMonth = () => {
    const date = dateFns.format(dateFns.addMonths(this.state.currentMonth, 1), "YYYY-MM-DD");
    this.getEventList(date);
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
  };

  prevMonth = () => {
 
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
  };
   fetchStudentList = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    StudentService.findStudentList({ userId: userDetails._id })
      .then((res) => {
        const mappedStudents = res.map((student) => ({
          ...student,
          fullName: `${student.firstName} ${student.lastName}`,
        }));
        this.setState({studentList:mappedStudents});
        if(mappedStudents.length>0){
            // getEventList(mappedStudents[0]._id);
            this.setState({
              studentId:mappedStudents[0]._id,
              classId:mappedStudents[0].classId._id,
            })
            const date = dateFns.format(dateFns.subMonths(this.state.currentMonth, 1), "YYYY-MM-DD");
            this.getEventList(date,mappedStudents[0]._id,mappedStudents[0].classId._id);
        }
        
      })
      .catch((err) => console.error(err));
  };
  handleStudentClick = (studentId,classId) =>{

    this.setState({
      studentId:studentId,
      classId:classId 
    })
console.log(studentId)
const date = dateFns.format(dateFns.subMonths(this.state.currentMonth, 1), "YYYY-MM-DD");

this.getEventList(date,studentId,classId);
  }
  render() {
    return (<>
     <Card sx={{ maxWidth: 345 }}>
                {this.state.studentList.length>1 &&( <Box style={{ margin: 15 }}>
                 <StudentCards studentList={this.state.studentList} onStudentSelect={this.handleStudentClick} />
          
                 </Box>)}
               </Card>
               <br />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <div className="calendar">
        

          {this.renderHeader()}
          {this.renderDays()}
          {this.renderCells()}

          {/* Conditionally render the card if there's an event with images */}
        
        </div>
      </Container>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <div className="headertitle">
          {this.state.userselectedDate? <h5> {this.state.userselectedDate} Assignment</h5>:''}
           
          </div>
          {this.state.event && this.state.event?.length > 0 && (
  <Grid container spacing={3} sx={{ mt: 2 }}>
    {this.state.event.map((image, index) => (
      <Grid item xs={12} md={6} key={index}>
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <img
            src={image.imageURL.length?image.imageURL[0]:''}  // Use mapped image URL
            alt={`Event Image ${index + 1}`}
            style={{ width: "100%", height: "auto" }}
          />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {image.eventName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {image.observation}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {image.date}
            </Typography>
          </CardContent>
          <CardActions sx={{ mt: "auto", justifyContent: "flex-end" }}>
            {image.video && (
              <a href={image.video} target="_blank" rel="noopener noreferrer">
                Watch on YouTube
              </a>
            )}
          </CardActions>
        </Card>
      </Grid>
    ))}
  </Grid>
)}

        </Container>
        </>
    );
  }
}

export default Calendar;
